<template>
  <div class="flex flex-col h-screen">
    <Nav />
    <div class="flex flex-col flex-grow">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
  import Nav from '@/components/Nav.vue';
  import Footer from '@/components/sections/Footer.vue';

  export default {
    name: 'Main',
    components: {
      Nav,
      Footer
    }
  };
</script>
